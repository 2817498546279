import {TableSchema} from "components/table/schema/SchemaModel";
import {PartnerStatistics} from "api/statistics/PartnerStatisticsResource";
import {
    DefaultRenderer,
    PartnerCompanyNameRenderer,
    PartnerLinkRenderer, PartnerStatusRenderer
} from "components/table/util/UmzugEasyCellRenderers";
import {calculateDaysRemaining, formatToLocaleTextWithDate} from "utils/DateUtil";
import {mapToCamelCasedString} from "utils/StringUtils";
import {formatToGermanCurrency} from "utils/NumbersUtil";
import {UmzugEasyTableProps} from "components/table/UmzugEasyTable";
import {getUmzugEasyTableProps} from "components/table/schema/SchemaUtil";
import {differenceInDays} from "date-fns";

export const getPartnersStatisticsTableSchema = (
    showPreviousMonths: boolean,
    onSortByChange: (key: string, direction: 'asc' | 'desc' | undefined) => void,
    t: (key: string) => string,
    partnersStatistics?: PartnerStatistics[],
    partnersStatisticsDate?: Date,
): UmzugEasyTableProps<PartnerStatistics> => {
    return {
        stickyTable: getUmzugEasyTableProps<PartnerStatistics>({
            groupSchema: stickyGroupSchema,
            schema: createStickyTableSchema(onSortByChange),
            rows: partnersStatistics,
            translationPrefix: 'list.table.header',
            translationFile: 'partnersReport',
            sxGroupHeaders: {height: '50px'},
            sxHeaders: {height: '50px'},
        }),
        table: getUmzugEasyTableProps<PartnerStatistics>({
            groupSchema: showPreviousMonths ? createEnhancedGroupSchema(t, partnersStatisticsDate) : groupSchema,
            schema: showPreviousMonths ? createEnhancedTableSchema(onSortByChange, t) : createTableSchema(onSortByChange, t),
            rows: partnersStatistics,
            translationPrefix: 'list.table.header',
            translationFile: 'partnersReport',
            sxGroupHeaders: {height: '50px', 'th': { textAlign: 'center' } },
            sxHeaders: {height: '50px'},
        }),
        noDataMessageKey: 'partnersReport:list.table.noData',
        customStyles: {
            tableBody: { 'tr': { height: '55px' } }
        }
    }
}

const getOfflineSinceValue = (
    t: (key: string) => string,
    offlineDate?: Date,
) => {
    if(offlineDate) {
        return `${formatToLocaleTextWithDate(offlineDate.toString())} (${differenceInDays(new Date(), new Date(offlineDate))} ${t('list.table.values.days')})`
    }
    return ''
}

const getPreviousMonthsIndexAndYear = (date?: Date): number[][] => {
    const baseDate = date || new Date()
    let currentMonth = baseDate.getUTCMonth()
    let currentYear = baseDate.getUTCFullYear()
    const previousMonthsArray: number[][] = []
    for(let i = 0; i < 12; i++) {
        if(currentMonth < 0) {
            currentMonth = 11;
            currentYear--;
        }
        previousMonthsArray.push([currentMonth, currentYear]);
        currentMonth--;
    }
    return previousMonthsArray;
}

const stickyGroupSchema: TableSchema<PartnerStatistics>[] = [
    {key: 'empty', order: 1, colSpan: 1},
]

const groupSchema: TableSchema<PartnerStatistics>[] = [
    {key: 'empty', order: 1, colSpan: 6},
    {key: 'currentMonth', order: 2, colSpan: 3},
    {key: 'currentYear', order: 100, colSpan: 3},
    {key: 'lastYear', order: 101, colSpan: 3},
]

const createEnhancedGroupSchema = (
    t: (key: string) => string,
    partnersStatisticsDate?: Date,
): TableSchema<PartnerStatistics>[] => {
    const previousMonthsArray = getPreviousMonthsIndexAndYear(partnersStatisticsDate)
    return [
        ...groupSchema,
        {key: 'backwardMonth1', translationKey: `months.${previousMonthsArray[1][0]}`, translationKeyParam: previousMonthsArray[1][1].toString(), order: 10, colSpan: 3},
        {key: 'backwardMonth2', translationKey: `months.${previousMonthsArray[2][0]}`, translationKeyParam: previousMonthsArray[2][1].toString(), order: 11, colSpan: 3},
        {key: 'backwardMonth3', translationKey: `months.${previousMonthsArray[3][0]}`, translationKeyParam: previousMonthsArray[3][1].toString(), order: 12, colSpan: 3},
        {key: 'backwardMonth4', translationKey: `months.${previousMonthsArray[4][0]}`, translationKeyParam: previousMonthsArray[4][1].toString(), order: 13, colSpan: 3},
        {key: 'backwardMonth5', translationKey: `months.${previousMonthsArray[5][0]}`, translationKeyParam: previousMonthsArray[5][1].toString(), order: 14, colSpan: 3},
        {key: 'backwardMonth6', translationKey: `months.${previousMonthsArray[6][0]}`, translationKeyParam: previousMonthsArray[6][1].toString(), order: 15, colSpan: 3},
        {key: 'backwardMonth7', translationKey: `months.${previousMonthsArray[7][0]}`, translationKeyParam: previousMonthsArray[7][1].toString(), order: 16, colSpan: 3},
        {key: 'backwardMonth8', translationKey: `months.${previousMonthsArray[8][0]}`, translationKeyParam: previousMonthsArray[8][1].toString(), order: 17, colSpan: 3},
        {key: 'backwardMonth9', translationKey: `months.${previousMonthsArray[9][0]}`, translationKeyParam: previousMonthsArray[9][1].toString(), order: 18, colSpan: 3},
        {key: 'backwardMonth10', translationKey: `months.${previousMonthsArray[10][0]}`, translationKeyParam: previousMonthsArray[10][1].toString(), order: 19, colSpan: 3},
        {key: 'backwardMonth11', translationKey: `months.${previousMonthsArray[11][0]}`, translationKeyParam: previousMonthsArray[11][1].toString(), order: 20, colSpan: 3},
    ]
}

const createStickyTableSchema = (
    onSortByChange: (key: string, direction: 'asc' | 'desc' | undefined) => void,
): TableSchema<PartnerStatistics>[] => {
    return [
        {key: 'partnerName', translationKey: 'partnerName', order: 1, cellRenderer: (row) => PartnerLinkRenderer(row.partnerCwid, PartnerCompanyNameRenderer(row.partnerName)), onSortChange: (key, direction) => onSortByChange(key, direction)},
    ]
}

const createTableSchema = (
    onSortByChange: (key: string, direction: 'asc' | 'desc' | undefined) => void,
    t: (key: string) => string
): TableSchema<PartnerStatistics>[] => {
    return [
        {key: 'scoutId', translationKey: 'customerId', order: 1, cellRenderer: (row) => PartnerLinkRenderer(row.partnerCwid, DefaultRenderer(row.scoutId?.toString() || '')), onSortChange: (key, direction) => onSortByChange(key, direction)},
        {key: 'ownerDetails', translationKey: 'accountOwner', order: 2, cellRenderer: (row) => PartnerLinkRenderer(row.partnerCwid, DefaultRenderer(row.ownerDetails || '')), onSortChange: (key, direction) => onSortByChange(key, direction)},
        {key: 'status.isOnline', translationKey: 'onlineOffline', order: 3, cellRenderer: (row) => PartnerLinkRenderer(row.partnerCwid, PartnerStatusRenderer(row.status.isOnline)), onSortChange: (key, direction) => onSortByChange(key, direction)},
        {key: 'status.offlineDate', translationKey: 'offlineSince', order: 4, cellRenderer: (row) => PartnerLinkRenderer(row.partnerCwid, DefaultRenderer(getOfflineSinceValue(t, row.status.offlineDate))), onSortChange: (key, direction) => onSortByChange(key, direction)},
        {key: 'status.offlineReason', translationKey: 'offlineReason', order: 5, cellRenderer: (row) => PartnerLinkRenderer(row.partnerCwid, DefaultRenderer(row.status.offlineReason ? t(`list.table.values.offlineReason.${mapToCamelCasedString(row.status.offlineReason)}`) : '')), onSortChange: (key, direction) => onSortByChange(key, direction)},
        {key: 'status.deactivationDate', translationKey: 'deactivationIn', order: 6, cellRenderer: (row) => PartnerLinkRenderer(row.partnerCwid, DefaultRenderer(row.status.deactivationDate ? `${calculateDaysRemaining(row.status.deactivationDate)} ${t('list.table.values.days')}` : '')), onSortChange: (key, direction) => onSortByChange(key, direction)},
        {key: 'revenue.currentMonth', translationKey: 'revenue', order: 7, cellRenderer: (row) => PartnerLinkRenderer(row.partnerCwid, DefaultRenderer(formatToGermanCurrency(row.revenue.currentMonth, false))), onSortChange: (key, direction) => onSortByChange(key, direction)},
        {key: 'leadsCount.currentMonth', translationKey: 'leads', order: 8, cellRenderer: (row) => PartnerLinkRenderer(row.partnerCwid, DefaultRenderer(row.leadsCount.currentMonth)), onSortChange: (key, direction) => onSortByChange(key, direction)},
        {key: 'avgPrice.currentMonth', translationKey: 'average', order: 9, cellRenderer: (row) => PartnerLinkRenderer(row.partnerCwid, DefaultRenderer(row.avgPrice?.currentMonth ? formatToGermanCurrency(row.avgPrice.currentMonth) : '')), onSortChange: (key, direction) => onSortByChange(key, direction)},

        {key: 'revenue.currentYear', translationKey: 'revenue', order: 110, cellRenderer: (row) => PartnerLinkRenderer(row.partnerCwid, DefaultRenderer(formatToGermanCurrency(row.revenue.currentYear, false))), onSortChange: (key, direction) => onSortByChange(key, direction)},
        {key: 'leadsCount.currentYear', translationKey: 'leads', order: 111, cellRenderer: (row) => PartnerLinkRenderer(row.partnerCwid, DefaultRenderer(row.leadsCount.currentYear)), onSortChange: (key, direction) => onSortByChange(key, direction)},
        {key: 'avgPrice.currentYear', translationKey: 'average', order: 112, cellRenderer: (row) => PartnerLinkRenderer(row.partnerCwid, DefaultRenderer(row.avgPrice?.currentYear ? formatToGermanCurrency(row.avgPrice.currentYear) : '')), onSortChange: (key, direction) => onSortByChange(key, direction)},
        {key: 'revenue.lastYear', translationKey: 'revenue', order: 113, cellRenderer: (row) => PartnerLinkRenderer(row.partnerCwid, DefaultRenderer(formatToGermanCurrency(row.revenue.lastYear, false))), onSortChange: (key, direction) => onSortByChange(key, direction)},
        {key: 'leadsCount.lastYear', translationKey: 'leads', order: 114, cellRenderer: (row) => PartnerLinkRenderer(row.partnerCwid, DefaultRenderer(row.leadsCount.lastYear)), onSortChange: (key, direction) => onSortByChange(key, direction)},
        {key: 'avgPrice.lastYear', translationKey: 'average', order: 115, cellRenderer: (row) => PartnerLinkRenderer(row.partnerCwid, DefaultRenderer(row.avgPrice?.lastYear ? formatToGermanCurrency(row.avgPrice.lastYear) : '')), onSortChange: (key, direction) => onSortByChange(key, direction)},
    ]
}

const createEnhancedTableSchema = (
    onSortByChange: (key: string, direction: 'asc' | 'desc' | undefined) => void,
    t: (key: string) => string
): TableSchema<PartnerStatistics>[] => {
    return [
        ...createTableSchema(onSortByChange, t),
        {key: 'revenue.backwardMonth1', translationKey: 'revenue', order: 10, cellRenderer: (row) => PartnerLinkRenderer(row.partnerCwid, DefaultRenderer(formatToGermanCurrency(row.revenue.backwardMonth1, false))), onSortChange: (key, direction) => onSortByChange(key, direction)},
        {key: 'leadsCount.backwardMonth1', translationKey: 'leads', order: 11, cellRenderer: (row) => PartnerLinkRenderer(row.partnerCwid, DefaultRenderer(row.leadsCount.backwardMonth1)), onSortChange: (key, direction) => onSortByChange(key, direction)},
        {key: 'avgPrice.backwardMonth1', translationKey: 'average', order: 12, cellRenderer: (row) => PartnerLinkRenderer(row.partnerCwid, DefaultRenderer(row.avgPrice?.backwardMonth1 ? formatToGermanCurrency(row.avgPrice.backwardMonth1) : '')), onSortChange: (key, direction) => onSortByChange(key, direction)},
        {key: 'revenue.backwardMonths2', translationKey: 'revenue', order: 13, cellRenderer: (row) => PartnerLinkRenderer(row.partnerCwid, DefaultRenderer(formatToGermanCurrency(row.revenue.backwardMonths2, false))), onSortChange: (key, direction) => onSortByChange(key, direction)},
        {key: 'leadsCount.backwardMonths2', translationKey: 'leads', order: 14, cellRenderer: (row) => PartnerLinkRenderer(row.partnerCwid, DefaultRenderer(row.leadsCount.backwardMonths2)), onSortChange: (key, direction) => onSortByChange(key, direction)},
        {key: 'avgPrice.backwardMonths2', translationKey: 'average', order: 15, cellRenderer: (row) => PartnerLinkRenderer(row.partnerCwid, DefaultRenderer(row.avgPrice?.backwardMonths2 ? formatToGermanCurrency(row.avgPrice.backwardMonths2) : '')), onSortChange: (key, direction) => onSortByChange(key, direction)},
        {key: 'revenue.backwardMonths3', translationKey: 'revenue', order: 16, cellRenderer: (row) => PartnerLinkRenderer(row.partnerCwid, DefaultRenderer(formatToGermanCurrency(row.revenue.backwardMonths3, false))), onSortChange: (key, direction) => onSortByChange(key, direction)},
        {key: 'leadsCount.backwardMonths3', translationKey: 'leads', order: 17, cellRenderer: (row) => PartnerLinkRenderer(row.partnerCwid, DefaultRenderer(row.leadsCount.backwardMonths3)), onSortChange: (key, direction) => onSortByChange(key, direction)},
        {key: 'avgPrice.backwardMonths3', translationKey: 'average', order: 18, cellRenderer: (row) => PartnerLinkRenderer(row.partnerCwid, DefaultRenderer(row.avgPrice?.backwardMonths3 ? formatToGermanCurrency(row.avgPrice.backwardMonths3) : '')), onSortChange: (key, direction) => onSortByChange(key, direction)},
        {key: 'revenue.backwardMonths4', translationKey: 'revenue', order: 19, cellRenderer: (row) => PartnerLinkRenderer(row.partnerCwid, DefaultRenderer(formatToGermanCurrency(row.revenue.backwardMonths4, false))), onSortChange: (key, direction) => onSortByChange(key, direction)},
        {key: 'leadsCount.backwardMonths4', translationKey: 'leads', order: 20, cellRenderer: (row) => PartnerLinkRenderer(row.partnerCwid, DefaultRenderer(row.leadsCount.backwardMonths4)), onSortChange: (key, direction) => onSortByChange(key, direction)},
        {key: 'avgPrice.backwardMonths4', translationKey: 'average', order: 21, cellRenderer: (row) => PartnerLinkRenderer(row.partnerCwid, DefaultRenderer(row.avgPrice?.backwardMonths4 ? formatToGermanCurrency(row.avgPrice.backwardMonths4) : '')), onSortChange: (key, direction) => onSortByChange(key, direction)},
        {key: 'revenue.backwardMonths5', translationKey: 'revenue', order: 22, cellRenderer: (row) => PartnerLinkRenderer(row.partnerCwid, DefaultRenderer(formatToGermanCurrency(row.revenue.backwardMonths5, false))), onSortChange: (key, direction) => onSortByChange(key, direction)},
        {key: 'leadsCount.backwardMonths5', translationKey: 'leads', order: 23, cellRenderer: (row) => PartnerLinkRenderer(row.partnerCwid, DefaultRenderer(row.leadsCount.backwardMonths5)), onSortChange: (key, direction) => onSortByChange(key, direction)},
        {key: 'avgPrice.backwardMonths5', translationKey: 'average', order: 24, cellRenderer: (row) => PartnerLinkRenderer(row.partnerCwid, DefaultRenderer(row.avgPrice?.backwardMonths5 ? formatToGermanCurrency(row.avgPrice.backwardMonths5) : '')), onSortChange: (key, direction) => onSortByChange(key, direction)},
        {key: 'revenue.backwardMonths6', translationKey: 'revenue', order: 25, cellRenderer: (row) => PartnerLinkRenderer(row.partnerCwid, DefaultRenderer(formatToGermanCurrency(row.revenue.backwardMonths6, false))), onSortChange: (key, direction) => onSortByChange(key, direction)},
        {key: 'leadsCount.backwardMonths6', translationKey: 'leads', order: 26, cellRenderer: (row) => PartnerLinkRenderer(row.partnerCwid, DefaultRenderer(row.leadsCount.backwardMonths6)), onSortChange: (key, direction) => onSortByChange(key, direction)},
        {key: 'avgPrice.backwardMonths6', translationKey: 'average', order: 27, cellRenderer: (row) => PartnerLinkRenderer(row.partnerCwid, DefaultRenderer(row.avgPrice?.backwardMonths6 ? formatToGermanCurrency(row.avgPrice.backwardMonths6) : '')), onSortChange: (key, direction) => onSortByChange(key, direction)},
        {key: 'revenue.backwardMonths7', translationKey: 'revenue', order: 28, cellRenderer: (row) => PartnerLinkRenderer(row.partnerCwid, DefaultRenderer(formatToGermanCurrency(row.revenue.backwardMonths7, false))), onSortChange: (key, direction) => onSortByChange(key, direction)},
        {key: 'leadsCount.backwardMonths7', translationKey: 'leads', order: 29, cellRenderer: (row) => PartnerLinkRenderer(row.partnerCwid, DefaultRenderer(row.leadsCount.backwardMonths7)), onSortChange: (key, direction) => onSortByChange(key, direction)},
        {key: 'avgPrice.backwardMonths7', translationKey: 'average', order: 30, cellRenderer: (row) => PartnerLinkRenderer(row.partnerCwid, DefaultRenderer(row.avgPrice?.backwardMonths7 ? formatToGermanCurrency(row.avgPrice.backwardMonths7) : '')), onSortChange: (key, direction) => onSortByChange(key, direction)},
        {key: 'revenue.backwardMonths8', translationKey: 'revenue', order: 31, cellRenderer: (row) => PartnerLinkRenderer(row.partnerCwid, DefaultRenderer(formatToGermanCurrency(row.revenue.backwardMonths8, false))), onSortChange: (key, direction) => onSortByChange(key, direction)},
        {key: 'leadsCount.backwardMonths8', translationKey: 'leads', order: 32, cellRenderer: (row) => PartnerLinkRenderer(row.partnerCwid, DefaultRenderer(row.leadsCount.backwardMonths8)), onSortChange: (key, direction) => onSortByChange(key, direction)},
        {key: 'avgPrice.backwardMonths8', translationKey: 'average', order: 33, cellRenderer: (row) => PartnerLinkRenderer(row.partnerCwid, DefaultRenderer(row.avgPrice?.backwardMonths8 ? formatToGermanCurrency(row.avgPrice.backwardMonths8) : '')), onSortChange: (key, direction) => onSortByChange(key, direction)},
        {key: 'revenue.backwardMonths9', translationKey: 'revenue', order: 34, cellRenderer: (row) => PartnerLinkRenderer(row.partnerCwid, DefaultRenderer(formatToGermanCurrency(row.revenue.backwardMonths9, false))), onSortChange: (key, direction) => onSortByChange(key, direction)},
        {key: 'leadsCount.backwardMonths9', translationKey: 'leads', order: 35, cellRenderer: (row) => PartnerLinkRenderer(row.partnerCwid, DefaultRenderer(row.leadsCount.backwardMonths9)), onSortChange: (key, direction) => onSortByChange(key, direction)},
        {key: 'avgPrice.backwardMonths9', translationKey: 'average', order: 36, cellRenderer: (row) => PartnerLinkRenderer(row.partnerCwid, DefaultRenderer(row.avgPrice?.backwardMonths9 ? formatToGermanCurrency(row.avgPrice.backwardMonths9) : '')), onSortChange: (key, direction) => onSortByChange(key, direction)},
        {key: 'revenue.backwardMonths10', translationKey: 'revenue', order: 37, cellRenderer: (row) => PartnerLinkRenderer(row.partnerCwid, DefaultRenderer(formatToGermanCurrency(row.revenue.backwardMonths10, false))), onSortChange: (key, direction) => onSortByChange(key, direction)},
        {key: 'leadsCount.backwardMonths10', translationKey: 'leads', order: 38, cellRenderer: (row) => PartnerLinkRenderer(row.partnerCwid, DefaultRenderer(row.leadsCount.backwardMonths10)), onSortChange: (key, direction) => onSortByChange(key, direction)},
        {key: 'avgPrice.backwardMonths10', translationKey: 'average', order: 39, cellRenderer: (row) => PartnerLinkRenderer(row.partnerCwid, DefaultRenderer(row.avgPrice?.backwardMonths10 ? formatToGermanCurrency(row.avgPrice.backwardMonths10) : '')), onSortChange: (key, direction) => onSortByChange(key, direction)},
        {key: 'revenue.backwardMonths11', translationKey: 'revenue', order: 40, cellRenderer: (row) => PartnerLinkRenderer(row.partnerCwid, DefaultRenderer(formatToGermanCurrency(row.revenue.backwardMonths11, false))), onSortChange: (key, direction) => onSortByChange(key, direction)},
        {key: 'leadsCount.backwardMonths11', translationKey: 'leads', order: 41, cellRenderer: (row) => PartnerLinkRenderer(row.partnerCwid, DefaultRenderer(row.leadsCount.backwardMonths11)), onSortChange: (key, direction) => onSortByChange(key, direction)},
        {key: 'avgPrice.backwardMonths11', translationKey: 'average', order: 42, cellRenderer: (row) => PartnerLinkRenderer(row.partnerCwid, DefaultRenderer(row.avgPrice?.backwardMonths11 ? formatToGermanCurrency(row.avgPrice.backwardMonths11) : '')), onSortChange: (key, direction) => onSortByChange(key, direction)},
    ]
}