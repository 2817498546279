import React, {ReactElement} from "react";
import GeneralSettings from "./GeneralSettings";
import LeadDaysSettings from "./LeadDaysSettings";
import ShopSettings from "./ShopSettings";
import {Formik} from "formik";
import {Shape} from "../../../../../components/form/FormInput";
import {
    BookingsSettings as BookingsSettingsResource
} from "../../../../../api/bookingsSettings/BookingsSettingsResource";
import RelocationExclusionDays from "./RelocationExclusionDays";
import {useBookingsSettingsContext} from "./BookingsSettingsContext";
import CancellationRules from "./CancellationRules";
import {number, object, string} from "yup";
import ShopSubscriptionSettings from "pages/partner/components/bookings/bookingsSettings/shop/ShopSubscriptionSettings";
import {
    useInitShopSubscriptionSettingsContext
} from "pages/partner/components/bookings/bookingsSettings/shop/ShopSubscriptionSettingsContext";
import {
    ShopSubscriptionSettingsContext
} from "pages/partner/components/bookings/bookingsSettings/shop/ShopSubscriptionSettingsContext.model";
import {usePartnerContext} from "pages/partner/shared/PartnerContext";


export interface BookingsSettingsForm extends Shape {
    leadThrottling?: number
    freeLeadsLimit?: number
    minRelocationDelay?: number
    maxRelocationDelay?: number
    priceGroup1?: number
    priceGroup2?: number
    priceGroup3?: number | null
    priceGroupOther?: number
}

export const bookingsSettingsToBookingsSettingsForm = (settings?: BookingsSettingsResource): BookingsSettingsForm => ({
    leadThrottling: settings?.leadThrottling,
    freeLeadsLimit: settings?.freeLeadsLimit,
    freeLeadsReceived: settings?.freeLeadsReceived,
    minRelocationDelay: settings?.relocationDelayRange.min,
    maxRelocationDelay: settings?.relocationDelayRange.max,
    priceGroup1: settings?.shopPricesByApartmentArea.priceGroup1,
    priceGroup2: settings?.shopPricesByApartmentArea.priceGroup2,
    priceGroup3: settings?.shopPricesByApartmentArea.priceGroup3,
    priceGroupOther: settings?.shopPricesByApartmentArea.priceGroupOther,
    leadDays: settings?.leadDays,
    cancellationRules: settings?.cancellationRules,
})

const errorKeys = {
    cancellationRulesLengthMax: 'booking.bookingsSettings.cancellationRules.inputs.errors.cancellationRulesLengthMax',
    freeLeadsLimitMoreThanZero: 'booking.bookingsSettings.cancellationRules.inputs.errors.freeLeadsLimitMoreThanZero',
    freeLeadsLimitIsRequired: 'booking.bookingsSettings.cancellationRules.inputs.errors.freeLeadsLimitIsRequired',
    leadThrottlingLessThanOneHundredPercent: 'booking.bookingsSettings.cancellationRules.inputs.errors.leadThrottlingLessThanOneHundredPercent',
    leadThrottlingShouldBeGreaterThanZero: 'booking.bookingsSettings.cancellationRules.inputs.errors.leadThrottlingShouldBeGreaterThanZero',

    minimumRelocationDelayShouldBeGreaterThanZero: 'booking.bookingsSettings.cancellationRules.inputs.errors.minimumRelocationDelayShouldBeGreaterThanZero',
    maximumRelocationDelayShouldBeGreaterThanZero: 'booking.bookingsSettings.cancellationRules.inputs.errors.maximumRelocationDelayShouldBeGreaterThanZero',

    smallPriceIsRequired: 'booking.bookingsSettings.cancellationRules.inputs.errors.fieldRequired',
    mediumPriceIsRequired: 'booking.bookingsSettings.cancellationRules.inputs.errors.fieldRequired',
    largePriceIsRequired: 'booking.bookingsSettings.cancellationRules.inputs.errors.fieldRequired',
    otherPriceIsRequired: 'booking.bookingsSettings.cancellationRules.inputs.errors.fieldRequired',

    smallPriceShouldBeGraterThanZeroDotOne: 'booking.bookingsSettings.cancellationRules.inputs.errors.priceShouldBeGreaterThanZeroDotOne',
    mediumPriceShouldBeGraterThanZeroDotOne: 'booking.bookingsSettings.cancellationRules.inputs.errors.priceShouldBeGreaterThanZeroDotOne',
    largePriceShouldBeGraterThanZeroDotOne: 'booking.bookingsSettings.cancellationRules.inputs.errors.priceShouldBeGreaterThanZeroDotOne',
    otherPriceShouldBeGraterThanZeroDotOne: 'booking.bookingsSettings.cancellationRules.inputs.errors.priceShouldBeGreaterThanZeroDotOne',
}

export const schema = object<BookingsSettingsForm>().shape({
    cancellationRules: string()
        .transform((value: string | null) => {
            if (value == null || value.trim() === '') {
                return null
            }
            return value
        })
        .nullable()
        .max(255, errorKeys.cancellationRulesLengthMax),
    leadThrottling: number().min(0, errorKeys.leadThrottlingShouldBeGreaterThanZero)
        .max(100, errorKeys.leadThrottlingLessThanOneHundredPercent)
        .required(errorKeys.leadThrottlingLessThanOneHundredPercent),
    freeLeadsLimit: number().min(0, errorKeys.freeLeadsLimitMoreThanZero)
        .required(errorKeys.freeLeadsLimitIsRequired),
    minRelocationDelay: number()
        .transform((value) => Number.isNaN(value) ? null : value )
        .nullable()
        .min(1, errorKeys.minimumRelocationDelayShouldBeGreaterThanZero),
    maxRelocationDelay: number()
        .transform((value) => Number.isNaN(value) ? null : value )
        .nullable()
        .min(1, errorKeys.maximumRelocationDelayShouldBeGreaterThanZero),
    priceGroup1: number().min(0.1, errorKeys.smallPriceShouldBeGraterThanZeroDotOne)
        .required(errorKeys.smallPriceIsRequired),
    priceGroup2: number().min(0.1, errorKeys.mediumPriceShouldBeGraterThanZeroDotOne)
        .required(errorKeys.mediumPriceIsRequired),
    priceGroup3: number().min(0.1, errorKeys.largePriceShouldBeGraterThanZeroDotOne)
        .nullable(),
    priceGroupOther: number().min(0.1, errorKeys.otherPriceShouldBeGraterThanZeroDotOne)
        .required(errorKeys.otherPriceIsRequired),
});

const BookingsSettings: React.FC = (): ReactElement => {
    const {partner} = usePartnerContext()
    const {bookingsSettings, saveChanges} = useBookingsSettingsContext()
    const shopSubscriptionSettingsCtx = useInitShopSubscriptionSettingsContext(partner?.cwid)
    return (
        <Formik initialValues={bookingsSettingsToBookingsSettingsForm(bookingsSettings)}
                enableReinitialize={true}
                validateOnBlur={true}
                validationSchema={schema}
                onSubmit={(values: BookingsSettingsForm) => saveChanges(values)}
        >
            <>
                <CancellationRules />
                <GeneralSettings />
                <LeadDaysSettings />
                <RelocationExclusionDays />
                <ShopSettings bookingsSettings={bookingsSettings}/>

                <ShopSubscriptionSettingsContext.Provider value={shopSubscriptionSettingsCtx}>
                    <ShopSubscriptionSettings/>
                </ShopSubscriptionSettingsContext.Provider>
            </>
        </Formik>
    )
}
export default BookingsSettings;
