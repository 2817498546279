import React, {FC, ReactElement, useRef} from "react";
import {Box, Theme, Typography, useTheme} from "@mui/material";
import DatePicker from "components/form/DatePicker";
import {useTranslation} from "react-i18next";
import RequestsAndLeadsReport, {RequestsAndLeadsReportRef} from "pages/reports/overview/RequestsAndLeadsReport";
import {formatToLocaleTextWithDate} from "utils/DateUtil";
import RequestTypesReport, {RequestTypesReportRef} from "pages/reports/overview/RequestsTypesReport";
import RequestSourcesReport, {RequestSourcesReportRef} from "pages/reports/overview/RequestsSourcesReport";
import DateRangeTypePicker from "pages/reports/overview/DateRangeTypePicker";
import ChipIcon from "components/shared/ChipIcon";
import AutorenewIcon from '@mui/icons-material/Autorenew';
import useDateRanges from "pages/reports/overview/useDateRanges";

const countryCodes = ['DE', 'CH', 'AT']

const OverviewReport: FC = (): ReactElement => {
    const theme = useTheme<Theme>()
    const reportsTranslation = useTranslation('reports').t
    const {
        dateRangeType,
        startDate,
        endDate,
        comparisonStartDate,
        comparisonEndDate,
        goBack,
        goForward,
        setDateRangeType,
        setCustomRange
    } = useDateRanges()

    const mainLeadsReportRef = useRef<RequestsAndLeadsReportRef>(null);
    const comparisonLeadsReportRef = useRef<RequestsAndLeadsReportRef>(null);
    const mainTypesReportRef = useRef<RequestTypesReportRef>(null);
    const comparisonTypesReportRef = useRef<RequestTypesReportRef>(null);
    const mainSourcesReportRef = useRef<RequestSourcesReportRef>(null);
    const comparisonSourcesReportRef = useRef<RequestSourcesReportRef>(null);

    const handleRefreshData = () => {
        mainLeadsReportRef.current?.fetchStatistics()
        comparisonLeadsReportRef.current?.fetchStatistics()
        mainTypesReportRef.current?.fetchStatistics()
        comparisonTypesReportRef.current?.fetchStatistics()
        mainSourcesReportRef.current?.fetchStatistics()
        comparisonSourcesReportRef.current?.fetchStatistics()
    };

    return (
        <Box sx={{display: "flex", flexDirection: "column", alignItems: "center"}}>
            <Box sx={{maxWidth: "1500px"}}>
                <Box sx={{
                    display: "grid",
                    gridTemplateColumns: {
                        xs: "1fr",
                        lg: "1fr 1fr"
                    },
                    gap: "15px"
                }}>
                    <Box sx={{marginTop: "10px"}}>
                        <DatePicker initialValue={startDate}
                                    onChange={(date) => setCustomRange(date || startDate, endDate)}
                                    disableFuture={true}
                                    isUTC={false}
                                    sx={{marginLeft: "0"}}
                        />
                        <DatePicker initialValue={endDate}
                                    onChange={(date) => setCustomRange(startDate, date || endDate)}
                                    disableFuture={true}
                                    isUTC={false}
                        />
                        <ChipIcon
                            sx={{marginTop: "10px"}}
                            onClick={handleRefreshData}
                            icon={<AutorenewIcon sx={{height: "26px", fill: theme.palette.common.white}}/>}/>
                    </Box>
                    <Box sx={{marginTop: "20px"}}>
                        <DateRangeTypePicker
                            dateRangeType={dateRangeType}
                            onClickPrevious={goBack}
                            onClickNext={goForward}
                            onDateRangeTypeChange={setDateRangeType}
                        />
                    </Box>
                    <Typography>
                        {
                            `${reportsTranslation("partner.headline.mainDateRangeHeader")}
                            ${formatToLocaleTextWithDate(startDate)} - ${formatToLocaleTextWithDate(endDate)}`
                        }
                    </Typography>
                    <Typography>
                        {
                            `${reportsTranslation("partner.headline.comparisonDateRangeHeader")} 
                            ${formatToLocaleTextWithDate(comparisonStartDate)} - ${formatToLocaleTextWithDate(comparisonEndDate)}`
                        }
                    </Typography>

                    <RequestsAndLeadsReport ref={mainLeadsReportRef} startDate={startDate} endDate={endDate} countryCodes={countryCodes}/>
                    <RequestsAndLeadsReport ref={comparisonLeadsReportRef} startDate={comparisonStartDate} endDate={comparisonEndDate}
                                            countryCodes={countryCodes}/>

                    <RequestTypesReport ref={mainTypesReportRef} startDate={startDate} endDate={endDate}/>
                    <RequestTypesReport ref={comparisonTypesReportRef} startDate={comparisonStartDate} endDate={comparisonEndDate}/>

                    <RequestSourcesReport ref={mainSourcesReportRef} startDate={startDate} endDate={endDate} countryCodes={countryCodes}/>
                    <RequestSourcesReport ref={comparisonSourcesReportRef} startDate={comparisonStartDate} endDate={comparisonEndDate}
                                          countryCodes={countryCodes}/>
                </Box>
            </Box>
        </Box>
    );
};

export default OverviewReport;
