import {TableModel} from "./model/UmzugEasyTableModel";
import {makeStyles, useTheme} from "@mui/styles";
import {Box, Paper, paperClasses, styled, Table as MuiTable, TableBody, TableContainer, Theme} from "@mui/material";
import React from "react";
import UmzugEasyTableHeader from "./UmzugEasyTableHeader";
import UmzugEasyTableRows, {UmzugEasyTableRowProps} from "./UmzugEasyTableRows";
import {TableTheme, UmzugEasyTableContext, useTableContext} from './UmzugEasyTableContext';
import {useTranslation} from 'react-i18next';
import TableRowVisibilityDetector from "./TableRowVisibilityDetector";
import {SxProps} from "@mui/system";

export type UmzugEasyTableStyles = {
    tableContainer?: SxProps<Theme>;
    table?: SxProps<Theme>;
    tableBody?: SxProps<Theme>;
}

export type UmzugEasyTableProps<T> = {
    table: TableModel<T>
    stickyTable?: TableModel<T> // we can use this value if we want to stick values in table
    isHeaderSticky?: boolean,
    height?: string,
    onRowClick?: (data: T) => void,
    isRowActive?: (data: T) => boolean
    theme?: TableTheme,
    noDataMessageKey: string,
    onLastRowRendered?: () => void,
    customStyles?: UmzugEasyTableStyles,

};
export type TableProps<T> = {
    model: UmzugEasyTableRowProps<T>
    isSticky?: boolean,
    isHeaderSticky?: boolean,
    height?: string,
    onRowClick?: (data: T) => void,
    onLastRowRendered?: () => void,
}

const StyledPaper = styled(Paper)(({theme}) => {
    const { theme: tableTheme } = useTableContext();
    return {
        [`&.${paperClasses.root}`]: {
            boxShadow: "none",
            overflowX: "auto",
            backgroundColor: "inherit",
            borderBottom: (tableTheme === 'simple') ? "none" : "2px solid",
            borderBottomColor: theme.palette.background.paper,
            borderRadius: 0
        },
        [`&.${paperClasses.root}.sticky-table`]: {
            display: "inline-table",
            width: "fit-content",
            boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.08)",
            zIndex: 2,
            height: "max-content"
        },
        [`&.${paperClasses.root}.normal-table`]: {
            flexGrow: "1",
            minWidth: "20%",
            zIndex: 1
        }
    }
});

const tableStyles = makeStyles((theme: Theme) => ({
    background: {
        backgroundColor: theme.palette.background.default,
    }
}));

const Table = <T extends unknown>(props: TableProps<T>) => {
    const classes = props.isSticky ? 'sticky-table' : 'normal-table';
    const { customStyles } = useTableContext();
    return (
        <TableContainer sx={{ ...(customStyles?.tableContainer || {}), height: props.height }}
                        component={StyledPaper}
                        classes={{root: classes}}>
            <MuiTable stickyHeader={props.isHeaderSticky} sx={customStyles?.table}>
                <UmzugEasyTableHeader
                    groupHeaders={props.model.content.groupHeaders}
                    headers={props.model.content.headers}
                    sxGroupHeaders={props.model.content.sxGroupHeaders}
                    sxHeaders={props.model.content.sxHeaders}
                />
                <TableBody sx={customStyles?.tableBody}>
                    <UmzugEasyTableRows {...props.model} onClick={props.onRowClick}/>
                    { props.onLastRowRendered && <TableRowVisibilityDetector onIntersection={props.onLastRowRendered}/> }
                </TableBody>
            </MuiTable>
        </TableContainer>
    )
}

const NoDataMessage = (props: {message: string}) => {
    const theme = useTheme<Theme>()
    return (
        <Box sx={{
            fontSize: '18px',
            color: theme.palette.text.secondary,
            fontWeight: 600
        }}>{props.message}</Box>
    )
}

const UmzugEasyTable = <T extends unknown>(props: UmzugEasyTableProps<T>): JSX.Element => {
    const classes = tableStyles();

    const dataPresent = props.table.content.rows.length > 0;

    const {t} = useTranslation();

    return <>
      { dataPresent && <UmzugEasyTableContext.Provider value={{theme: props.theme || 'default', isRowActive: props.isRowActive, customStyles: props.customStyles}}>
          <div className={classes.background}
               style={{display: "flex"}}>
              {
                props.stickyTable &&
                <Table model={props.stickyTable}
                       isSticky={true}
                       isHeaderSticky={props.isHeaderSticky}
                       height={props.height}
                       onRowClick={props.onRowClick}/>
              }
              <Table model={props.table}
                     height={props.height}
                     isHeaderSticky={props.isHeaderSticky}
                     onRowClick={props.onRowClick}
                     onLastRowRendered={props.onLastRowRendered}/>
          </div>
        </UmzugEasyTableContext.Provider>
      }
      { !dataPresent && <NoDataMessage message={t(props.noDataMessageKey)}/> }
    </>
}
export default UmzugEasyTable
