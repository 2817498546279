import React, {ReactElement, useState, useImperativeHandle, forwardRef, useCallback} from "react"
import { getRequestTypesStatistics } from "api/statistics/StatisticsApi"
import ReportTable, { Formatter, ReportTableRow, ReportTableRowValues } from "components/reportTable/ReportTable"
import { currencyFormatter, fractionFormatter, toStringFormatter } from "pages/reports/utils/formatters"
import MultipleRowSkeleton from "components/feedback/MultipleRowSkeleton"
import { useTranslation } from "react-i18next"
import { getAggregatedStatistics } from "pages/reports/overview/RequestSourcesStatisticsService"
import { Statistics } from "api/statistics/Statistics"
import useDebounceTime from "hooks/DebounceTime";

type RequestTypesReportProps = {
    startDate: Date
    endDate: Date
}

export type RequestTypesReportRef = {
    fetchStatistics: () => void
}

const RequestTypesReport = forwardRef<RequestTypesReportRef, RequestTypesReportProps>(
    ({ startDate, endDate }, ref): ReactElement => {
        const { t } = useTranslation("reports")
        const headerOf = (column: string) => t(`partner.header.${column}`)
        const [statistics, setStatistics] = useState<Statistics[]>([])
        const [isLoading, setLoading] = useState<boolean>(false)

        const headers = [
            "requestType",
            "requests",
            "revenuePerRequest",
            "leads",
            "revenuePerLead",
            "multiplier",
            "totalRevenue"
        ].map(headerOf)

        const formatters: Array<Formatter> = [
            toStringFormatter,
            currencyFormatter,
            toStringFormatter,
            currencyFormatter,
            fractionFormatter,
            currencyFormatter
        ]

        const aggregationFunction = (rowValues: ReportTableRowValues[]) => {
            const aggregatedStatistics = getAggregatedStatistics(
                rowValues.map((values: ReportTableRowValues) => ({
                    requestCount: values[0],
                    leadCount: values[2],
                    revenue: values[5]
                }))
            )
            return [
                aggregatedStatistics.totalRequests,
                aggregatedStatistics.revenuePerRequest,
                aggregatedStatistics.totalLeads,
                aggregatedStatistics.revenuePerLead,
                aggregatedStatistics.multiplier,
                aggregatedStatistics.totalRevenue
            ]
        }

        const aggregationSettings = {
            aggregationRowLabel: t("partner.aggregationLabel"),
            aggregationFunction
        }

        const rows: ReportTableRow[] = statistics.map(s => ({
            id: t(`partner.requestTypes.rowId.basic`),
            values: [
                s.requestCount,
                s.revenue / s.requestCount,
                s.leadCount,
                s.revenue / s.leadCount,
                s.leadCount / s.requestCount,
                s.revenue
            ]
        }))

        const fetchStatistics = useCallback(() => {
            setLoading(true)
            getRequestTypesStatistics(startDate, endDate)
                .then(statistics => setStatistics(statistics))
                .finally(() => setLoading(false))
        }, [startDate, endDate])

        useImperativeHandle(ref, () => ({
            fetchStatistics
        }))

        useDebounceTime(() => {
            if (startDate && endDate) {
                fetchStatistics()
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [startDate, endDate])

        if (isLoading) return <MultipleRowSkeleton rows={5} fontSize={"35px"} styles={{ height: "200px", width: "640px" }} />

        return (
            <ReportTable
                headers={headers}
                rows={rows}
                formatters={formatters}
                aggregationSettings={aggregationSettings}
            />
        )
    }
)

export default RequestTypesReport
